<script>
import "./plugins/rangeslider.css"
import "./plugins/rangeslider.js"

export default {
	props:{
		min:{default:0},
		max:{default:10},
		value: Number,
	},
	mounted: function(){
		setTimeout(() => {
			$(this.$el).rangeslider({
				polyfill: false,
				// Callback function
				onInit: function () { },
				// Callback function
				onSlide: (position, value)=>{
					this.$emit('input', value);
				},
				// Callback function
				onSlideEnd: (position, value)=>{
          this.$emit("SlideEnd", value)
        }
			});
		}, 200);
	},
	watch:{
		value:function (value) {
			$(this.$el).val(value).rangeslider('update', true);
		}
	}
}
</script>

<template>
  <input :min="min" :max="max" type="text">
</template>
